import React from "react";
import { Link } from "react-router-dom";

function MonBlog(props) {
  return (
    <div className="bg-white drop-shadow-lg rounded-lg p-2 px-4 pb-9 pt-2 flex flex-col gap-4">
      <div className="h-72 rounded-lg object-contain overflow-hidden group animation duration-300">
        <img
          src={props.blogimage}
          alt=""
          className="w-full h-full rounded-lg animation duration-300 hover:scale-125 cursor-pointer"
        />
        <div className=" bg-black p-1">
          <Link
            to="/"
            className="absolute text-[14px] text-gray-700 cursor-pointer -mt-12 mx-2 group-hover:-mt-16 animation duration-300 bg-gray-100 px-7 py-2 rounded-md hover:bg-purple-700 hover:text-gray-100"
          >
            Consulter
          </Link>
        </div>
      </div>

      <div className="flex flex-col justify-between max-h-full h-20 pt-1 gap-1">
        <div>
          <span className="font-semibold text-xl">{props.title}</span>
        </div>

        <div className="flex justify-between">
          <span>{props.author}</span>
          <span className="text-gray-400 text-[13px]">{props.date}</span>
        </div>
      </div>
    </div>
  );
}

export default MonBlog;
