import React from "react";
import PetitSection1 from "../components/PetitSection1";
import Title from "../components/Title";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Newsletter from "../Partials/Newsletter/Newsletter";
import Transformation from "../components/Transformation";
import security from "../assets/security.png";
import Activitycard from "./Activitycard/Activitycard";
import GoToTop from "../components/GoToTop";
import { useTranslation } from "react-i18next";

function Detailsecurite() {
  const { t } = useTranslation();

  return (
    <div>
      <GoToTop />
      <Navbar />
      <PetitSection1 titre="Sécurité" newtitre="détail" />
      <Title header__text="Conseil en Sécurité" body__text={t("title")} />

      <Transformation
        images={security}
        header=" La sécurité informatique consiste à protéger un système informatique contre toute violation, intrusion, dégradation ou vol de données au sein du système d’information.

              Avec l’essor d’internet, et l’utilisation par la majorité des entreprises et des organisation de processus informatisés, les menaces visant les systèmes d’informations n’ont cessés d’augmenter et de se sophistiquer, faisant aujourd’hui de la sécurité informatique une nécessité pour tous les types de structure. Les actions préventives sont les premières actions à mettre en oeuvre pour assurer la sécurité informatique de son entreprise. Elles permettent de réduire le nombre de failles, d’intrusions, de tentatives de piratages et de pertes de données. "
      />
      <Activitycard service="securite" />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Detailsecurite;
