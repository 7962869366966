import React from "react";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Services from "./Services/Services";
import Partners from "./Partners/Partners";
import Newsletter from "../Partials/Newsletter/Newsletter";
import GoToTop from "../components/GoToTop";
import Processus from "./Processus/Processus";
import Contact from "./Contact/Contact";
import Hero from "./Hero/Hero";
import About from "./About/About";

export default function Home() {
  return (
    <>
      <GoToTop />
      <Navbar />
      <Hero />
      <About />
      <Services />
      <Partners />
      <Processus />
      <Contact />
      <Newsletter />
      <Footer />
    </>
  );
}
