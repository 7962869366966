import React from "react";
import PetitSection1 from "../components/PetitSection1";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Newsletter from "../Partials/Newsletter/Newsletter";
import Services from "./Services/Services";

function Service() {
  return (
    <>
      <Navbar />
      <PetitSection1 titre="Nos Services" newtitre="Service" />
      <Services />
      <Newsletter />
      <Footer />
    </>
  );
}

export default Service;
