import React from "react";
import PetitSection1 from "../components/PetitSection1";
import Title from "../components/Title";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Newsletter from "../Partials/Newsletter/Newsletter";
import Transformation from "../components/Transformation";
import infrastructure from "../assets/infrastructure.png";
import Activitycard from "./Activitycard/Activitycard";
import GoToTop from "../components/GoToTop";
import { useTranslation } from "react-i18next";

function DetailInfrastructure() {
  const { t } = useTranslation();

  return (
    <div>
      <GoToTop />
      <Navbar />
      <PetitSection1 titre="Infrastructure" newtitre="détail" />
      <Title header__text="Conseil en Infrastructure" body__text={t("title")} />

      <Transformation
        images={infrastructure}
        header=" Une infrastructure informatique comprend des composants matériels, logiciels et réseau, un système d'exploitation ainsi qu'un système de stockage des données qui sont utilisés pour fournir des services et solutions informatiques. Ces produits peuvent être des applications logicielles téléchargeables qui s'exécutent sur les ressources informatiques existantes (une solution de stockage logiciel par exemple), ou des solutions en ligne proposées par des prestataires de services (comme une solution de type IaaS)."
      />
      <Activitycard service="infrastructure" />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default DetailInfrastructure;
