import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/logo.png";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";

const savoirfaire = [
  {
    name: "Transformation Digital",
    description: "De vos processus journalier",
    href: "/transformation",
    icon: ChartPieIcon,
  },
  {
    name: "Infrastructure",
    description: "De votre matériel, logiciel, réseau, etc",
    href: "/infrastructure",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Sécurité",
    description: "Contre toute intrusion, dégradation, etc",
    href: "/securite",
    icon: FingerPrintIcon,
  },
  {
    name: "Expérience Client",
    description: "Pour une meilleur productivité",
    href: "/experience",
    icon: SquaresPlusIcon,
  },
  {
    name: "Outils de production",
    description: "Utilisé pour divers secteurs d'activités",
    href: "/outil",
    icon: ArrowPathIcon,
  },
];
const callsToAction = [
  { name: "Une rencontre", href: "/contact", icon: PlayCircleIcon },
  { name: "Nous contactez", href: "/contact", icon: PhoneIcon },
];

function Navbar() {
  const [scroll, setScroll] = useState(0);
  const { t } = useTranslation();

  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const ChangeColor = () => {
    setScroll(window.scrollY);
  };
  window.addEventListener("scroll", ChangeColor);

  return (
    <div className={click ? "main-container" : ""} onClick={() => Close()}>
      <nav
        className={
          scroll <= 80 ? "navbar navbar_transparent" : "navbar navbar_white "
        }
        onClick={(e) => e.stopPropagation()}
      >
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img
              src={logo}
              width={53}
              alt="Logo"
              className="bg-white rounded-lg"
            />
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                {t("acceuil")}
              </NavLink>
            </li>

            {/* ##### Qui Sommes Nous #### */}
            <li className="nav-item hidden md:inline-block">
              <Popover.Group
                activeClassName="active"
                onClick={click ? handleClick : null}
                className="lg:flex nav-link"
              >
                <Popover className="relative">
                  <Popover.Button className="nav-links flex items-center gap-0 text-sm font-normal leading-6 text-gray-100 outline-none">
                    {t("qui_somme")}
                    <ChevronDownIcon
                      className="h-5 w-5 flex-none ml-2 text-gray-400"
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute -left-8 top-full z-10 mt-2 w-full max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                      <div className="px-4">
                        <div className="group relative flex items-center rounded-lg pt-4 text-sm leading-6">
                          <div className="flex flex-col">
                            <Link
                              to="/mission"
                              className="font-medium self-start text-gray-900 rounded-md px-4 py-1 mb-2"
                            >
                              <span className="">-</span>
                              <span className="ml-2">Notre Mission</span>
                            </Link>
                            <Link
                              to="/mission"
                              className="font-medium self-start text-gray-900 rounded-md px-4 py-1 mb-2"
                            >
                              <span className="">-</span>
                              <span className="ml-2">Notre Vision</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* Contact */}
                      <div className="grid divide-gray-900/5 bg-gray-50">
                        <Link
                          to="/contact"
                          className="flex items-center justify-center gap-3 p-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                        >
                          <PhoneIcon
                            className="h-5 w-5 flex-none text-gray-400"
                            aria-hidden="true"
                          />
                          Nous Contactez
                        </Link>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </Popover.Group>
            </li>

            {/* ####################### */}
            {/* This is only for Mobile Screen */}
            <li className="nav-item md:hidden">
              <NavLink
                exact
                to="/mission"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                {t("qui_somme")}
              </NavLink>
            </li>
            {/* Ends Here */}

            {/* ##### Savoir Faire #### */}
            <li className="nav-item hidden md:inline-block">
              <Popover.Group
                activeClassName="active"
                className="nav-link lg:flex nav-link"
                onClick={click ? handleClick : null}
              >
                <Popover className="relative">
                  <Popover.Button className="nav-links flex items-center gap-0 text-sm font-normal leading-6 text-gray-100 outline-none">
                    {t("services")}
                    <ChevronDownIcon
                      className="h-5 w-5 flex-none ml-2 text-gray-400"
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute -left-8 top-full z-10 mt-2 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                      <div className="p-4">
                        {savoirfaire.map((item) => (
                          <div
                            key={item.name}
                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                          >
                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                              <item.icon
                                className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="flex flex-col">
                              <Link
                                to={item.href}
                                className="font-semibold self-start text-gray-900"
                              >
                                {item.name}
                                <span className="absolute inset-0" />
                              </Link>
                              <p className="mt-1 text-gray-600">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                        {callsToAction.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                          >
                            <item.icon
                              className="h-5 w-5 flex-none text-gray-400"
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </Popover.Group>
            </li>

            {/* ####################### */}
            {/* This is only for Mobile Screen */}
            <li className="nav-item md:hidden">
              <NavLink
                exact
                to="/mission"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                {t("services")}
              </NavLink>
            </li>
            {/* Ends Here */}

            <li className="nav-item">
              <NavLink
                exact
                to="/blog"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                {t("blog")}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                {t("contact_us")}
              </NavLink>
            </li>
          </ul>

          <div className="drop_button nav-icon">
            <>
              <input
                type="checkbox"
                id="hamburgerEffect"
                onClick={handleClick}
              ></input>
              <label id="burger" for="hamburgerEffect">
                <div></div>
                <div></div>
                <div></div>
              </label>
            </>
          </div>
        </div>
        <div className="line_nav"></div>
      </nav>
    </div>
  );
}

export default Navbar;
