import React from "react";
import "./Partners.css";
import Title from "../../components/Title";
import ibm from "../../assets/ibm.png";
import ms from "../../assets/ms.png";
import Cisco from "../../assets/Cisco.png";
import { useTranslation } from "react-i18next";

function Partners() {
  const { t } = useTranslation();

  const partners = [ibm, ms, Cisco, ibm];

  return (
    <div className="container_custom">
      <Title
        header__text={t("partenaire")}
        body__text="ils nous font confiance"
      />

      <div className="flex flex-wrap gap-4 md:gap-9 items-center justify-center">
        {partners.map((item, key) => {
          return (
            <div
              key={key}
              className="p-4 w-[190px] group h-44 flex items-center justify-center bg-white shadow"
            >
              <img
                src={item}
                alt="Partner"
                className="w-full animation duration-300 group-hover:scale-110"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Partners;
