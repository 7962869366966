import React from 'react'

function CardActivity(props) {
  return (
    <div className='card__activity'>
            <div className='first' >
                  <img src={props.imgs} width="50" alt="" />
            </div>
            <div className='second'>
                  <div className='second__text'>
                    <h3>{props.activity__title}</h3>
                    <p>{props.activity__substitle.substring(0, 100)}...</p>
                  </div>
            </div>
            <div className='third'>
             
            </div>
            
    </div>
  )
}

export default CardActivity