import React from "react";
import icons from "../assets/up.png";
function GoToTop(props) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div
        className="button__fixe cursor-pointer fixed bottom-10 right-8 z-50 bg-gradient-to-r p-3 from-[#8a2c76] via-purple-700 to-purple-600"
        id={props.id}
        onClick={scrollToTop}
      >
        <img src={icons} alt="" />
      </div>
    </div>
  );
}

export default GoToTop;
