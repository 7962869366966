import React from "react";

function PetitSection1(props) {
  return (
    <div>
      <div className="section__small">
        {/* <img src={background} alt="background" className='petit__section__img' /> */}
        <div className="petit__section__text mt-14 md:mt-8">
          <h1 id="sur">{props.titre}</h1>
          <p className="description">{props.description}</p>
          <h4>
            Accueil <span>/</span> <span>{props.newtitre}</span>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default PetitSection1;
