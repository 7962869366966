import React from "react";
import "./Newsletter.css";
import { useTranslation } from "react-i18next";

function Newsletter() {
  const { t } = useTranslation();

  return (
    <div className=" news">
      <div className="news__left">
        <div>
          <h1 className="new__text">{t("inscription")}</h1>
        </div>
        <div>
          <p>{t("inscription_info")}</p>
        </div>
      </div>
      <div className="news__right">
        <div className="bg-transparent  border-[1px] hover:border-purple-400 rounded-lg flex flex-row px-2 pl-4 py-1 w-full justify-between drop-shadow-sm">
          <input
            type="text"
            placeholder="Entrer votre email ici"
            className="bg-transparent pr-4 border-none outline-none text-[10px] md:text-[15px] text-black"
          />
          <div>
            <button
              type="button"
              className="w-74 p-2 py-3 text-[10px] md:text-[15px] md:px-5 bg-purple-900 hover:bg-purple-800 text-white rounded-lg"
            >
              Souscrire maintenant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
