import React from "react";
import ProcessusTravail from "../../components/ProcessusTravail";
import garanti from "../../assets/garanti.jfif";
import analyse from "../../assets/analyse.jfif";
import rencontre from "../../assets/rencontre.jfif";
import mode from "../../assets/mode.jfif";
import maintenance from "../../assets/maintance.jfif";
import ligne from "../../assets/ligne.png";
import "./Processus.css";
import Title from "../../components/Title";
import { useTranslation } from "react-i18next";

function Processus() {
  const { t } = useTranslation();

  const processus = [
    {
      id: 1,
      title: "La rencontre",
      image: rencontre,
    },
    {
      id: 2,
      title: "Analyse et Conception",
      image: analyse,
    },
    {
      id: 3,
      title: "Réalisation en mode agile",
      image: mode,
    },
    {
      id: 4,
      title: "Support et Maintenance",
      image: maintenance,
    },
    {
      id: 5,
      title: "Garanties et Evolutions",
      image: garanti,
    },
  ];

  return (
    <div className="processus">
      <Title
        header__text={t("processus")}
        body__text="Une méthodologie de travail agile afin de garantir la satisfaction cliente"
      />

      <div className="processus__content">
        {processus &&
          processus.map((item) => {
            return (
              <div className="content__card__image grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {processus.length !== item.id ? (
                  <img src={ligne} alt="Processus" className="ligne" />
                ) : null}
                <ProcessusTravail
                  image={item.image}
                  number={item.id}
                  text="Garanties et Evolutions"
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Processus;
