import React from "react";
import { FaCheck } from "react-icons/fa";
function Mission(props) {
  const Image = () => {
    return (
      <>
        <div className="mission__left">
          <div
            className="card__image aos-init aos-animate"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <img src={props.imag} width="400" alt="" />
          </div>
        </div>
      </>
    );
  };

  const Text = () => {
    return (
      <>
        <div className="mission__right">
          <div
            className="mission__header aos-init aos-animate"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <span className="mission__header__chec">
              <p className="chec__icon">
                <FaCheck size={15} color="white" />
              </p>
              <h3
                data-aos="fade-up"
                className="aos-init aos-animate"
                data-aos-duration="2000"
              >
                {props.header}
              </h3>
            </span>
          </div>
          <div
            className="mission__subtitle aos-init aos-animate"
            data-aos-duration="3000"
          >
            <p data-aos="fade-up">{props.subtitle}</p>
          </div>

          <div className="mission__header aos-init aos-animate"
            data-aos="zoom-in"
            data-aos-duration="1000">
            <span className="mission__header__chec">
              <p className="chec__icon">
                <FaCheck size={15} color="white" />
              </p>
              <h3
                data-aos="fade-up"
                className="aos-init aos-animate"
                data-aos-duration="2000"
              >
                {props.header1}
              </h3>
            </span>
          </div>
          <div
            className="mission__subtitle aos-init aos-animate"
            data-aos-duration="2000"
          >
            <p data-aos="fade-up" data-aos-duration="2000">{props.subtitle1}</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={props.color === "white" ? "  mission" : "  mission2"}>
      {props.position === "left" ? (
        <>
          <Image />
          <Text />
        </>
      ) : (
        <>
          <Text />
          <Image />
        </>
      )}
    </div>
  );
}

export default Mission;
