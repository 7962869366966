import React from 'react'
import './NotFound.css'
import notfound from '../../assets/notfound.jpg';
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'

function NotFound() {

  const navigate = useNavigate();

  const redirectTo = ()=>{
      navigate("/");
  }

  return (
    <>
         <div className='not__found'>
                 <img src={notfound} alt="" width={700} />
                 <div className='error_text'>Oops ! Page introuvable</div>
                 <div onClick={redirectTo}>
                 <Button  type=" btn btn__primary" label="Retouner à la page d'accueil" />
                 </div>
         </div>
    </>
  )
}

export default NotFound