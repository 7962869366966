import React from 'react'
import CardActivity from '../../components/CardActivity'
import training from '../../assets/training.png'
import code from '../../assets/code.png'
import pai from '../../assets/pai.png'
import assess from '../../assets/assess.png'
import sens from '../../assets/sens.png'
import techni from '../../assets/techni.png'
import route from '../../assets/route.png'
import erp from '../../assets/erp.png'
import collaboration from '../../assets/collaboration.png'
import managment from '../../assets/managment.png'
import time from '../../assets/times.png'
import peripherique from '../../assets/peripherique.png'
import network from '../../assets/network.png'
import server from '../../assets/server.png'
import cable from '../../assets/cable.png'
import control from '../../assets/control.png'
import camera from '../../assets/camera.png'
import power from '../../assets/power.png'
import imprime from '../../assets/imprime.png'
import gestion from '../../assets/gestion.png'
import marketing from '../../assets/marketing.png'
import mobilite from '../../assets/mobilite.png'
import point from '../../assets/point.png'

import './Activitycard.css'

function Activitycard(props) {


   function Transform (){
   return(
   <>
   <div className='activity'>
        <CardActivity
        imgs={assess}
        activity__title="Assessment"
         activity__substitle="Ce processus d’évaluation s’appuie sur des entretiens et des tests psychotechniques (tests d’aptitude et de personnalité). 
         "
        />
       
        <CardActivity
         imgs={training}
         activity__title="Formation"
          activity__substitle=" La formation professionnelle est le processus d'apprentissage qui permet à un salarié ou un demandeur d'emploi d'acquérir le savoir, le savoir-faire et le savoir-être (capacité et aptitude) .
          "
        />
        <CardActivity 
         imgs={code}
         activity__title="Dévéloppement d'application"
          activity__substitle=" Le développement d’une application web vous permet de disposer d’une application qui sera accessible depuis n’importe quel navigateur sur n’importe quel appareil.
          "
        />
          <CardActivity
           imgs={pai}
           activity__title=" Digitalisation du paiement"
            activity__substitle="  la digitalisation des paiements consiste en la numérisation de bout en bout des transactions financières, à savoir les recettes et les dépenses.
            "
          />
          
          </div>

          <div className='activity'>
        <CardActivity
        imgs={sens}
        activity__title="Awareness"
         activity__substitle="La Cyber-Security Awareness est usuellement assimilée dans les organisations comme « la prise de conscience des risques et dangers liés à la sécurité informatique».
         "
        />
       
        <CardActivity
         imgs={route}
         activity__title="Définition Roadmap"
          activity__substitle="  En tant que véritable manifeste agile et outil de cadrage, elle dresse la liste des tâches de votre plan projet selon un principe d'itération et d'intégration continue. 
          "
        />
        <CardActivity 
         imgs={techni}
         activity__title="AMOA"
          activity__substitle="Généralement, l'AMOA a pour tâche principale de créer une liaison fonctionnelle entre le maître d'oeuvre MOE et le MOA. 
          "
        />
         
          
          </div>
   </>
   )
   }


   
   function Securite (){
    return(
    <>
    <div className='activity'>
         <CardActivity
         imgs={assess}
         activity__title="De la mobilité"
          activity__substitle="  Regroupe les usages des technologies électroniques sans-fil. Elle permet d'accéder à des informations numériques qui peuvent être communiquées ou modifiées à distance . 
          "
         />
        
         <CardActivity
          imgs={peripherique}
          activity__title="Des terminaux et péripherique"
           activity__substitle="  Le terminal est un point d'accès de communication entre l'homme et un ordinateur central ou un réseau d'ordinateurs.
           "
         />
         <CardActivity 
          imgs={code}
          activity__title="Des applications"
          text="Voir plus"
           activity__substitle="Les applications dites courantes regroupent tous les outils et solutions logicielles utilisés quotidiennement.
           "
         />
           <CardActivity
            imgs={pai}
            activity__title=" Des infrastructure/réseau"
             activity__substitle="La mise en place d’une infrastructure réseau constitue une obligation pour toute société moderne et ambitieuse. Celle-ci s’apparente à la charpente de votre organisation informatique.
             "
           />
           
           </div>
 
           <div className='activity'>
         <CardActivity
         imgs={sens}
         activity__title="Gestion des identités et des accès"
          activity__substitle=" 
        C' est la pratique qui consiste à s’assurer que les personnes et les entités ayant une identité numérique ont le bon niveau d’accès aux ressources de l’entreprise . 
          "
         />
        
         <CardActivity
          imgs={route}
          activity__title="Test d'intrusion/test vulnérabilité"
           activity__substitle=" permettent de découvrir les failles dans vos systèmes qui permettent à des pirates de compromettre vos opérations et voler vos données
           "
         />
         <CardActivity 
          imgs={techni}
          activity__title="Audit et Sécurité"
           activity__substitle=" C'est un diagnostic de l’état de sécurité de votre système d’information pour en révéler d’éventuels failles ou dysfonctionnements qui pourraient compromettre vos activités.
           "
         />
           <CardActivity
            imgs={pai}
            activity__title="Assistance à la gouvernance de la sécurité"
             activity__substitle="C'est un processus continu faisant partie intégrante de la culture d'une entreprise, intégrant la gestion de risques et alignée stratégiquement sur ses objectifs s'affaires.
             "
           />
           
           </div>


           <div className='activity'>
         <CardActivity
         imgs={sens}
         activity__title="Sécurité defensive"
          activity__substitle=" Face à des menaces informatiques toujours plus sophistiquées et des attaques de plus en plus ciblées, la protection du système ... 
          "
         />
        
         <CardActivity
          imgs={route}
          activity__title="Sécurité offensive"
           activity__substitle=" identifie les vulnérabilités à corriger sans attendre une attaque réelle. En soumettant le système d'information à un test face aux .
           "
         />
         <CardActivity 
          imgs={techni}
          activity__title="Evaluation des risques"
           activity__substitle="  consiste en une inspection approfondie du lieu de travail en vue d'identifier entre autres les éléments, ... , 
           "
         />
         
           
           </div>
    </>
    )
    }


    function Infrastructure (){
      return(
      <>
      <div className='activity'>
           <CardActivity
           imgs={network}
           activity__title="Réseau"
            activity__substitle=" Indépendamment de la technologie sous-jacente, on porte généralement une vue matricielle sur ce qu'est un réseau.
            "
           />
          
           <CardActivity
            imgs={server}
            activity__title="Serveurs et Poste de travail"
             activity__substitle=" Il est maintenant utilisé pour divers systèmes d'exploitation.
             "
           />
           <CardActivity 
            imgs={cable}
            activity__title="Câblage"
             activity__substitle=" Ici il est question de prendre en considération le réseau internet et intranet de l'entreprise."
           />
             <CardActivity
              imgs={power}
              activity__title=" Energie"
               activity__substitle=" Un appareil informatique ou un système informatisé est un automate commandé par un ou plusieurs systèmes de traitement de l'information .
               "
             />
             
             </div>
   
             <div className='activity'>
           <CardActivity
           imgs={imprime}
           activity__title="Solution d'impression"
            activity__substitle=" qui permettent d'imprimer, de copier et numériser, deviennent aujourd'hui de véritables serveurs d'impression à base de disques durs
            "
           />
          
           <CardActivity
            imgs={control}
            activity__title="Contrôle d'accès"
             activity__substitle=" être utilisée pour déterminer les utilisateurs ou les programmes autorisés à voir ou à utiliser les ressources d'un environnement informatique
             "
           />
           <CardActivity 
            imgs={camera}
            activity__title="Video surveillance"
             activity__substitle=" installation des caméras de surveillance sur votre lieu de travail (bureau, magasin, entrepôt, etc.)
             "
           />
             
             
             </div>
      </>
      )
      }


      function Outil (){
        return(
        <>
        <div className='activity'>
             <CardActivity
             imgs={assess}
             activity__title="EAM (Entreprise Asset Managment)"
              activity__substitle=" combinaison de logiciels, de systèmes et de services utilisés pour maintenir et contrôler les actifs et équipements opérationnels.
              "
             />
            
             <CardActivity
              imgs={training}
              activity__title="Gestion de la production"
               activity__substitle=" ensemble des activités qui participent à la conception, planification des ressources (..),ordonnancement, enregistrement , traçabilité des activités de production,
               "
             />
             <CardActivity 
              imgs={erp}
              activity__title="ERP (Entreprise Resource Managment)"
               activity__substitle=" Les systèmes ERP relient une multitude de processus métier et permettent le flux de données entre eux. 
               "
             />
               <CardActivity
                imgs={managment}
                activity__title=" ECM (Entreprise Content Managment )"
                 activity__substitle=" La gestion de contenu d'entreprise ne fait pas référence à une technologie ou à un processus unique. Il s'agit d'un terme générique qui décrit la combinaison de méthodes 
                 "
               />
               
               </div>
     
               <div className='activity'>
             <CardActivity
             imgs={sens}
             activity__title="BPM (Business Process Managment)"
              activity__substitle=" utilise diverses méthodes pour améliorer un processus métier en l'analysant, en modélisant son fonctionnement dans différents scénarios
              "
             />
            
             <CardActivity
              imgs={route}
              activity__title="SIRH (Systeme d'Information des Ressources Humaines)"
               activity__substitle=" Les SIRH se dotent également de tableaux de bords et de Business intelligent pour analyser les données RH et, le cas échéant, aider à la prise de décision. , 
               "
             />
             <CardActivity 
              imgs={time}
              activity__title="Gestion des temps"
               activity__substitle="Avec un logiciel de gestion de temps, les employés pointent à l'aide d'un NIP sur un appareil qui enregistre automatiquement les heures exactes d'arrivée, de pause et de départ du travail.
               "
             />
               <CardActivity
                imgs={collaboration}
                activity__title=" Collaboration & Réseau sociaux"
                 activity__substitle=" Dans le domaine des réseaux sociaux , le terme de collaboration désigne généralement un accord ou une opération menée entre une marque et un influenceur digital .
                 "
               />
               
               </div>
        </>
        )
        }



        function Experience (){
          return(
          <>
          <div className='activity'>
               <CardActivity
               imgs={assess}
               activity__title="Portail web"
                activity__substitle="C'est une plateforme où l'on trouve des ressources et des services pour les internautes. Mais à la différence du site web, il faudra dans la majorité des cas utiliser un identifiant et un mot de passe pour accéder à ces contenus
                "
               />
              
               <CardActivity
                imgs={training}
                activity__title="Paiement en ligne/Mobile "
                 activity__substitle="C’est un vrai challenge pour choisir la solution de paiement adéquate pour lancer votre business en ligne de façon efficace.

                 Car au Cameroun, les solutions de paiement y en a un multitude.
                 "
               />
               <CardActivity 
                imgs={pai}
                activity__title="Paiement Marchand (sur TPE)"
                 activity__substitle="Indispensable chez un commerçant, le terminal de paiement électronique (TPE) permet de débiter le compte bancaire des clients et de créditer le compte professionnel du commerçant instantanément. Il offre de nombreux avantages : paiement sécurisés, tout type de cartes acceptées, risques d’erreurs moindres, etc.
                 "
               />
                 <CardActivity
                  imgs={point}
                  activity__title=" Digitalisation points de vente"
                   activity__substitle=" Elle désigne la fusion du commerce en ligne et des magasins physiques, souvent opposés. 
                   "
                 />
                 
                 </div>
       
                 <div className='activity'>
               <CardActivity
               imgs={mobilite}
               activity__title="Mobilité des forces de vente"
                activity__substitle=" Les forces de vente nomades sont plus efficaces lorsqu'elles ont en leur possession un outil pratique pour planifier les différentes tournées de prospection
                "
               />
              
               <CardActivity
                imgs={gestion}
                activity__title="Gestion de la relation client"
                 activity__substitle=" Lorem ipsum dolor sit,
                  amet consectetur adipisicing elit. Soluta deleniti recusandae , 
                 "
               />
               <CardActivity 
                imgs={marketing}
                activity__title="Marketing digitale"
                 activity__substitle=" Lorem ipsum dolor sit,
                  amet consectetur adipisicing elit. Soluta deleniti recusandae , 
                 "
               />
             
                 
                 </div>
          </>
          )
          }
    
  


  return (
    <div className=' active1'>
        <h2>
            Quelques activités clées de notre accompagnement
        </h2>
        {
              props.service === "transform" ?(
                    <Transform />
              ):props.service === "securite" 
              ?(
                <Securite />
              ):props.service === "infrastructure"? (
               <Infrastructure />
              ):props.service === "outil"?  (
               <Outil />
              ):props.service === "experience"? (
                <Experience />
              ):(
                <h1>rien</h1>
              )
             
        }
    </div>
  )
}

export default Activitycard