export const TRANSLATIONS_EN = {
  // header translate

  cameroun: "Douala-Cameroon",

  // navbar translation
  acceuil: "Home",
  qui_somme: "Who are we ?",
  services: "What we do",
  blog: "Blog",
  contact_us: "Contact Us",
  temoin: "Testimony",
  lien_rapide: "Quick Links",
  ouvert: "Open from Monday to Friday",
  // big  section

  en_savoir_plus: "LEARN MORE",
  contactez_nous: "CONTACT US",

  // title translate

  title:
    "we offer you quality services available to everyone and at an affordable cost",

  // service

  transformation_digi: "Digital transformation",
  outil: "production tools",
  experience: "Client experience",
  consultation: "Consultation",
  domaine: "Our areas of expertise",

  // partenaires
  partenaire: "Our partners",

  // contact

  contacter:
    "Need to contact us? fill out the form with your request or find the contact you wish to contact below",
  laisse_message: "Leave us a message",
  nom: "Last name",
  email: "E-mail",
  telephone: "phone",
  objet: "Object",
  message: "Enter your message here..",
  envoie: "Subscribe now",

  // newsletter

  inscription: "Subscribe to our newsletter ",
  inscription_info:
    "Get notified of updates and be the first to benotify of our promotional offers",

  processus: "Our Work Process",
};
