import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blog from "./Pages/Blog";
import Contacter from "./Pages/Contacter";
import DetailExperience from "./Pages/DetailExperience";
import DetailInfrastructure from "./Pages/DetailInfrastructure";
import DetailOutil from "./Pages/DetailOutil";
import Detailsecurite from "./Pages/Detailsecurite";
import DetailService from "./Pages/DetailService";
import Home from "./Pages/Home";
import Missions from "./Pages/Missions";
import Solution from "./Pages/Solution";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Service from "./Pages/Service";
import NotFound from "./Pages/NotFound/NotFound";
import DetailBlogs from "./Pages/DetailBlogs";
import Language from "./Pages/Language/Language";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/contact" element={<Contacter />}></Route>
          <Route path="/mission" element={<Missions />}></Route>
          <Route path="/service" element={<Service />}></Route>
          <Route path="/transformation" element={<DetailService />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/solution" element={<Solution />}></Route>
          <Route path="/securite" element={<Detailsecurite />}></Route>
          <Route
            path="/infrastructure"
            element={<DetailInfrastructure />}
          ></Route>
          <Route path="/outil" element={<DetailOutil />}></Route>
          <Route path="/detailblog" element={<DetailBlogs />}></Route>
          <Route path="/experience" element={<DetailExperience />}></Route>
          <Route path="*" exact={true} element={<NotFound />}></Route>
        </Routes>
      </Router>
      <Language />
    </>
  );
}

export default App;
