import React from "react";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Newsletter from "../Partials/Newsletter/Newsletter";
import Blogs from "./Blogs/Blogs";
import PetitSection1 from "../components/PetitSection1";
import GoToTop from "../components/GoToTop";

function Blog() {
  return (
    <div>
      <GoToTop id="#sur" />
      <Navbar />
      <PetitSection1 titre="Notre blog" newtitre="blog" description="" />

      <Blogs />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Blog;
