import React from "react";
import "./Component.css";

function SavoirfaireCard(props) {
  return (
    <div>
      <div className="specific_service__card">
        <div className="service1__text">
          <h2>{props.title}</h2>
          <p>{props.subtitle}</p>
        </div>
        <div className="service__image">
          <div>
            <img src={props.img} alt="" width={60} height={60} />
          </div>
          <div className="service__text bg-gradient-to-r from-[#8a2c76] via-purple-700 to-purple-600">
            <p>{props.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SavoirfaireCard;
