import React from "react";

function FormInput({ placeholder, type, value, name, onChange, required }) {
  return (
    // bg-[#ECEEF4]
    <input
      type={type ? type : "text"}
      value={value}
      name={name}
      className="w-full outline-none border-[1px] border-transparent rounded-md p-3 py-4 font-regular placeholder:text-[#b1b3b9] text-[13px] hover:border-purple-300 focus:border-purple-500"
      placeholder={placeholder ? placeholder : ""}
      onChange={onChange}
      required={required ? required : false}
    />
  );
}

export default FormInput;
