import React from "react";
import PetitSection1 from "../components/PetitSection1";
import Title from "../components/Title";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Newsletter from "../Partials/Newsletter/Newsletter";
import Transformation from "../components/Transformation";
import outil from "../assets/outil.png";
import Activitycard from "./Activitycard/Activitycard";
import GoToTop from "../components/GoToTop";
import { useTranslation } from "react-i18next";

function DetailOutil() {
  const { t } = useTranslation();

  return (
    <div>
      <GoToTop />
      <Navbar />
      <PetitSection1 titre="Outil de Production" newtitre="détail" />
      <Title header__text="Outils de Production" body__text={t("title")} />

      <Transformation
        images={outil}
        header=" De nos jours, l’informatique a un rôle central dans tous types d'entreprises, quel que soit le secteur d’activité. Depuis son apparition, plusieurs tâches sont devenues beaucoup plus simples à réaliser et à gérer. Vous pouvez facilement traiter toute sorte d’information. S'il existe quelques côtés négatifs à cette évolution technologique, nous en tirons surtout beaucoup d’avantages. Pour les entrepreneurs, les travailleurs, salariés ou étudiants, l’utilisation de solutions informatiques qui répondent à des besoins spécifiques est capitale dans leur vie quotidienne."
      />
      <Activitycard service="outil" />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default DetailOutil;
