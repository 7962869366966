import React from "react";
function Vision(props) {
  return (
    <div className="container vision">
      <div className="content__vision">
        <div className="vision__text">
          <p>{props.vision__text} </p>
        </div>
      </div>
      <div className="vision__image">
        <img src={props.image} width="900" alt="" />
      </div>
    </div>
  );
}

export default Vision;
