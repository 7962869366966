import React from "react";
import { useState } from "react";
import PetitSection1 from "../components/PetitSection1";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Newsletter from "../Partials/Newsletter/Newsletter";

function DetailBlogs(props) {
  const [letitre, setLetitre] = useState("");
  return (
    <div>
      <Navbar />
      <PetitSection1 titre={letitre} />
      {props.children}
      <Newsletter />
      <Footer />
    </div>
  );
}

export default DetailBlogs;
