import React from "react";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Newsletter from "../Partials/Newsletter/Newsletter";
import PetitSection1 from "../components/PetitSection1";
import GoToTop from "../components/GoToTop";

function Solution() {
  return (
    <div>
      <GoToTop />
      <Navbar />
      <PetitSection1 titre="Notre solution" newtitre="solution" />
      <div className="section__seperator"></div>
      <GoToTop />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Solution;
