import React from "react";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import PetitSection1 from "../components/PetitSection1";
import Newsletter from "../Partials/Newsletter/Newsletter";
import Mission from "../components/Mission";
import Title from "../components/Title";
import image2 from "../assets/image2.png";
import excellence from "../assets/excellence.png";
import image1 from "../assets/image1.png";
import GoToTop from "../components/GoToTop";
import { useTranslation } from "react-i18next";

function Missions() {
  const { t } = useTranslation();
  return (
    <div>
      <GoToTop />
      <Navbar />
      {/* <div className="section__seperator"></div> */}
      <PetitSection1
        titre="Nylstech Digital"
        // description="Alimenter les
        // Solutions l'innovation"
        newtitre="mission"
      />
      <Title header__text="Notre vision et mission" body__text={t("title")} />
      <Mission
        position="left"
        color="white"
        imag={image2}
        header=" Notre Vision
                "
        header1=" Notre Mission
                "
        subtitle=" Une afrique où toutes les entreprises capitalisent sur la puissance du numérique pour accélérer leur expansion économique sur le continent et à travers le monde entier.

                "
        subtitle1=" Etre votre partenaire de référence dans la sous-région pour la transformation et la pérenisation de votre business model pour plus de compétitivité à cet ère du digital.
                "
      />

      <Title
        header__text="Responsabilité et intégrité"
        body__text="nous vous offrons des services de qualité disponible à la portée de tous et à une coût aboedable"
      />
      <Mission
        position="lef"
        color="whit"
        imag={image1}
        header=" Responsabilité
                "
        header1=" Intégrité
                "
        subtitle=" Nous menons nos activité conformement à l'ethique et respectons les engagement pris.
                Nous sommes en tous points responsable devant nos clients, mais ne renonçons pas à l'autonomie qui nous protège contre les influences indues.
                Nous respectons les règles de confidentialité de nos clients.
                "
        subtitle1="Nous sommes objectifs dans les prestations que nous proposons.
                Nous recherchons en priorité la satisfaction de nos clients.
                Nous nous conformons au normes ethiques les plus rigoureuses et evitons les 
                influences indues ainsi que les conflits d'intérêts susceptibles d'affecter notre indépendance et l'impartialité dans notre mission.
                "
      />
      <Title
        header__text="Excellence"
        body__text="nous vous offrons des services de qualité disponible à la portée de tous et à une coût aboedable"
      />
      <Mission
        position="left"
        color="white"
        imag={excellence}
        header="Activité"
        header1=" Evolution
                "
        subtitle=" • Nous exerçons nos activités avec professionnalisme.
                 Nous élaborons des solutions sécurisées, innovantes et pratiques pour aider
                nos clients à rester compétitifs et concentrés sur leur cœur de métier

                "
        subtitle1=" Nous évaluons, analysons les méthodes, approches et appliquons les normes de bonnes pratiques dans l’ensemble de nos activités.

               
                 "
      />
      {/* <Valeur /> */}
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Missions;
