import React from 'react'

function Transformation(props) {
  return (
    <div>
         <div className='service__transformation '>
                <div className='transformation__image'>
                    <img src={props.images} width="350" alt="" />
                </div>
                <div className='transformation__text'>
                    <div className='mission__header'>
            
                        <p className='p2'>
                         
                            {props.header}
                        </p>
                    </div>
                  
                </div>
            </div>
    </div>
  )
}

export default Transformation