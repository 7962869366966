import React from "react";
import "./Component.css";

function ProcessusTravail(props) {
  return (
    <div className="processus__card">
      <div className="processus__image">
        <img src={props.image} alt="bac" />
      </div>
      <div className="processus__number">{props.number}</div>
      <div className="text-center font-normal opacity-70">{props.text}</div>
    </div>
  );
}

export default ProcessusTravail;
