import React from "react";
import { useNavigate } from "react-router-dom";

function Hero() {
  const navigate = useNavigate();

  return (
    <>
      <div className="h-[80vh] bg-gradient-to-tr from-black/90 via-black/90 to-black container_custom py-20 flex flex-col justify-center gap-8 items-center">
        <div className="text-white font-semibold uppercase pt-8 text-3xl md:text-4xl xl:text-7xl leading-10 xl:leading-[90px] text-center">
          <span className="bg-[#8d3173] px-4">Nylstech Digital</span> au coeur
          de votre transformation digitale
        </div>
        <div>
          <div className="flex gap-x-4">
            <button
              onClick={() => navigate("/service")}
              className="bg-gradient-to-r from-[#8a2c76] via-purple-700 text-[10px] md:text-[16px] to-purple-600 p-3 rounded-md text-white px-6 outline-none animation duration-300 skew-y-2 hover:from-purple-600 hover:to-purple-400 border-[1px] border-gray-100"
            >
              Découvrez nos services
            </button>
            <button
              onClick={() => navigate("/service")}
              className="bg-gradient-to-r from-[#8a2c76] via-purple-700 text-[10px] md:text-[16px] to-purple-600 p-3 rounded-md text-white px-6 outline-none animation duration-300 skew-y-2 hover:from-purple-600 hover:to-purple-400 hover:bg-white border-[1px] border-gray-100"
            >
              Nous contactez
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
