import React from "react";
import MonBlog from "../../components/MonBlog";
import bac from "../../assets/bac.jpg";
import digitalise from "../../assets/digitallise.jpg";
import transform from "../../assets/transform.jpg";
import Title from "../../components/Title";
import "./BLogs.css";
import { useTranslation } from "react-i18next";
function Blogs() {
  const { t } = useTranslation();
  const lists = [
    {
      id: 1,
      title: "Le meilleur logiciel de comptabilité au Cameroun",
      blogimage: bac,
      author: "Nchatchouang nathalie",
      date: "27/05/2022",
    },
    {
      id: 2,
      title: "Les enjeux de la transformation digitale pour les PME / TPE",
      blogimage: digitalise,
      author: "Nchatchouang nathalie",
      date: "08/01/2022",
    },
    {
      id: 3,
      title: "Créer un site internet gratuit : Comment faire en 2022 ",
      blogimage: transform,
      author: "Nchatchouang nathalie",
      date: "27/05/2022",
    },
  ];

  return (
    <div className="container__blog md:pb-8">
      <Title
        header__text="Restez au top de l'information"
        body__text={t("title")}
      />
      <div className="blog flex flex-row flex-wrap gap-6">
        {lists.map((list) => {
          return (
            <MonBlog
              key={list.id}
              title={list.title}
              blogimage={list.blogimage}
              author={list.author}
              date={list.date}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Blogs;
