import React from "react";

function Title(props) {
  return (
    <div className="flex items-center justify-center py-12 relative font-semibold">
      <div className="text-center">
        <h2
          className={`text-4xl md:text-5xl mb-2 ${
            props.color ? props.color : "text-primary"
          }`}
        >
          {props.header__text}
        </h2>
        <span className="text-gray-100 px-4 py-2 leading-10 mt-4 w-max-[70px] bg-purple-700 font-normal">
          {props.body__text}
        </span>
      </div>
    </div>
  );
}

export default Title;
