import React from 'react'

function Button(props) {
  return (
   <button  className={props.type} type="submit">
      {props.label}  
   </button>
  )
}

export default Button