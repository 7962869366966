import React, { useState } from "react";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
// import Swal from "sweetalert2";
import FormInput from "../../components/FormInput";
import contact from "../../assets/contact.png";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      subject,
      message,
    };
    sendEmail(e);

    // alert(JSON.stringify(data));
  };

  function sendEmail(e) {
    e.preventDefault();

    emailjs

      // To Be Used For Gesta
      // .sendForm(
      //   "service_2zylrlf",
      //   "template_p3usc1u",
      //   e.target,
      //   "j_Q05uV45kLEMi1es"
      // )
      .sendForm(
        "service_46k8ud9",
        "template_alcsqp9",
        e.target,
        "j_Q05uV45kLEMi1es"
      )
      .then(
        (result) => {
          Swal.fire(
            "Message Envoyé!",
            "Nous vous remercions pour votre intérêt!",
            "success"
          );
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
        },
        (error) => {
          // console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Sorry Erreur inconnu!",
            footer: "veuillez nous réesayez s'il vous plait",
          });
        }
      );
    e.target.reset();
  }

  return (
    <div className="p-2 bg-white py-14 px-4 md:px-20 flex flex-col gap-4 md:flex-row justify-between items-center w-full">
      <div className="w-full md:w-1/2">
        <div className="p-4">
          <img src={contact} alt="Contact" className="w-full" />
        </div>
      </div>
      <div className="w-full md:w-1/2 p-2 bg-[#f7f9fe] py-8 shadow-sm rounded-md">
        <div className="py-2 px-4">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row gap-4 mb-4">
              <FormInput
                placeholder="Votre nom"
                value={name}
                required={true}
                type="text"
                onChange={(e) => setName(e.target.value)}
              />
              <FormInput
                placeholder="Votre email"
                value={email}
                required={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <FormInput
                type="text"
                placeholder="Subject"
                value={subject}
                name="subject"
                onChange={(e) => setSubject(e.target.value)}
                required={true}
              />
            </div>

            <div className="mt-4">
              <textarea
                name="message"
                placeholder="Entrez votre message ici"
                id=""
                value={message}
                required="true"
                className="w-full outline-none px-3 pt-3 border-[1px] border-transparent rounded-md font-regular placeholder:text-[#b1b3b9] text-[13px] hover:border-purple-300 focus:border-purple-500"
                rows="6"
                onChange={(e) => setMessage(e.target.value)}
              >
                {message}
              </textarea>
            </div>

            <div className="mt-4">
              <button className="bg-gradient-to-r from-[#8a2c76] via-purple-700 to-purple-600 text-white p-2 rounded-md w-full">
                Envoyer un message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
