import React from "react";
import Title from "../components/Title";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Newsletter from "../Partials/Newsletter/Newsletter";
import PetitSection1 from "../components/PetitSection1";
import serviceimag from "../assets/service.png";
import serviceImage from "../assets/serviceImage.png";
import Transformation from "../components/Transformation";
import Vision from "../components/Vision";
import Activitycard from "./Activitycard/Activitycard";
import GoToTop from "../components/GoToTop";
import { useTranslation } from "react-i18next";

function DetailService() {
  const { t } = useTranslation();
  return (
    <div>
      <GoToTop />
      <Navbar />
      <PetitSection1 titre="Détail" newtitre="détail" />
      <div className="section__seperator"></div>
      <Title
        header__text="Conseil en transformation digitale"
        body__text={t("title")}
      />
      <Transformation
        images={serviceImage}
        header=" La transformation digitale permet à une entreprise de rester réactive face aux flux et reflux constants du marché. Un changement aussi complet et fondamental implique un examen complet non seulement des technologies, mais aussi de la culture, des opérations et du système de création de valeur d’une entreprise. Et lorsqu’une entreprise s’engage dans ce processus, le passage aux technologies numériques a une incidence sur ses produits et services, ses canaux de marketing et de distribution, ses processus commerciaux, ses chaînes logistiques et ses nouveaux partenaires sur le marché."
      />
      <Vision
        vision__text="NOTRE VISION DE L'ACCOMPAGNEMENT EN TRANSFORMATION DIGITALE"
        image={serviceimag}
      />

      <Activitycard service="transform" />

      <Newsletter />
      <Footer />
    </div>
  );
}

export default DetailService;
