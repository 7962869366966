import React from "react";
import "./Services.css";
import Title from "../../components/Title";
import ExpertiseCard from "../../components/ExpertiseCard";
import SavoirfaireCard from "../../components/SavoirfaireCard";
import { useTranslation } from "react-i18next";

const data = [
  {
    id: 1,
    title: "tranformation digitale",
    description: [
      {
        label: "Assessment",
      },
      {
        label: "Awareness",
      },
      {
        label: "Formation",
      },
      {
        label: "Définition Roadmap",
      },
      {
        label: "AMOA",
      },
      {
        label: "Digitalisation du paiement",
      },
      {
        label: "Développement d'application(Devops-Agile)",
      },
    ],
    link: "/transformation",
    img: require("../../assets/digitale.png"),
    time: "250",
  },
  {
    id: 2,
    title: "infrastructures",
    description: [
      {
        label: "Réseaux",
      },
      {
        label: "Serveurs et postes de travail",
      },
      {
        label: "Câblage",
      },
      {
        label: "Energie",
      },
      {
        label: "Solution d'impression",
      },
      {
        label: "Contrôle d'accès",
      },
      {
        label: "Video surveillance",
      },
    ],
    link: "/infrastructure",
    img: require("../../assets/LeftIcon5.png"),
    time: "500",
  },
  {
    id: 3,
    title: "experience client",
    description: [
      {
        label: "Portail web",
      },
      {
        label: "Paiement en ligne/Mobile",
      },
      {
        label: "Paiement Marchand (sur TPE)",
      },
      {
        label: "Digitalisation points de vente",
      },
      {
        label: "Mobilité des forces de vente",
      },
      {
        label: "Gestion de la relation client",
      },
      {
        label: "Marketing digital",
      },
    ],
    link: "/experience",
    img: require("../../assets/LeftIcon2.png"),
    time: "750",
  },
  {
    id: 4,
    title: "outils de production",
    description: [
      {
        label: "EAM (Gestion de la production)",
      },
      {
        label: "ERP (Entreprise Resource Planning)",
      },
      {
        label: "ECM (Entreprise Content Managment)",
      },
      {
        label: "BPM (Business Process Managment)",
      },
      {
        label: "SIRH (System d'information des Ressources Humaines)",
      },
    ],
    link: "/outil",
    img: require("../../assets/LeftIcon6.png"),
    time: "1000",
  },
  {
    id: 5,
    title: "sécurité",
    description: [
      {
        label: "De la mobilité",
      },
      {
        label: "Des terminaux et Péripheriques",
      },
      {
        label: "Des applications",
      },
      {
        label: "Des infrastructures/réseau",
      },
      {
        label: "Gestion des identités et des accès",
      },
      {
        label: "Test d'intrusion/test vulnérabilité",
      },
      {
        label: "Audit Sécurité",
      },
      {
        label: "Assistance à la gouvernance de la sécurité",
      },
      {
        label: "Sécurité defensive",
      },
      {
        label: "Sécurité offensive",
      },
      {
        label: "Evaluation des risques",
      },
    ],
    link: "/securite",
    img: require("../../assets/LeftIcon7.png"),
    time: "1250",
  },
];

const elemnts = [
  {
    id: 1,
    title: "Consulting",
    subtitle:
      "consiste pour une entreprise à solliciter les services d'un professionnel externe reconnu pour son expertise dans un domaine particulier. L’étape la plus importante de toute mission de consulting est généralement celle du diagnostic.",
    img: require("../../assets/consulte.png"),
    text: "1",
    time: "250",
  },
  {
    id: 2,
    title: "Integration",
    subtitle:
      "désigne le fait de connecter les données, les applications, les API et les appareils au sein de votre service informatique, dans le but d'augmenter l'efficacité, la productivité et l'agilité de votre entreprise.",
    img: require("../../assets/integration.png"),
    text: "2",
    time: "500",
  },

  {
    id: 3,
    title: "Formation",
    subtitle:
      " C'est le processus d'apprentissage qui permet à un salarié ou un demandeur d'emploi d'acquérir le savoir, le savoir-faire et le savoir-être (capacité et aptitude) nécessaires à l'exercice d'un métier ou d'une activité professionnelle1.",
    img: require("../../assets/learnin.png"),
    text: "3",
    time: "750",
  },

  {
    id: 4,
    title: "Proximité",
    subtitle:
      "Selon l’organisation de l’entreprise, le conseil après diagnostic est assuré à distance au sein d’une équipe constituée par téléphone ou connexion spécialisée. En fonction du contexte de l’entreprise, il peut aider  aux déploiements matériels (appareils d’interconnexions…) et logiciels.",
    img: require("../../assets/pox.png"),
    text: "4",
    time: "1000",
  },
];

function Services() {
  const { t } = useTranslation();

  return (
    <>
      {/* Domaines d'expertise */}
      <div className="container_custom bg-[#f7faff]">
        <Title header__text={t("domaine")} body__text={t("title")} />
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-6 gap-y-4"
          // data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-delay="400"
        >
          {data.map((item) => {
            return (
              <ExpertiseCard
                key={item.id}
                image={item.img}
                title={item.title}
                description={item.description}
                link={item.link}
              />
            );
          })}
        </div>
      </div>

      {/* Services */}
      <div className="specific__service container_custom w-full bg-red-700 p-4">
        <Title
          header__text={t("services")}
          body__text={t("title")}
          color="text-white"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 content-between py-6">
          {elemnts.map((element) => {
            return (
              <SavoirfaireCard
                title={element.title}
                subtitle={element.subtitle}
                img={element.img}
                text={element.text}
                timing={element.time}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Services;
