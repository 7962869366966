import React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { FaWhatsapp, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <footer className="bg-black/90 pt-8">
        <div className="content">
          <div className="top">
            <div className="logo-details">
              <i className="fab fa-slack"></i>
              <span className="text-white font-semibold">Nylstech Digital</span>
            </div>
            <div className="media-icons md:-translate-x-[120px]">
              <Link to="/" className="flex items-center justify-center">
                <FaWhatsapp color="white" size={16} className="icon" />
              </Link>
              <Link to="/" className="flex items-center justify-center">
                <FaTwitter color="white" size={16} className="icon" />
              </Link>
              <Link to="/" className="flex items-center justify-center">
                <FaLinkedin color="white" size={16} className="icon" />
              </Link>
            </div>
          </div>

          <div className="link-boxes">
            <ul className="box">
              <li className="link_name">{t("lien_rapide")}</li>
              <li>
                <Link to="/"> {t("acceuil")}</Link>
              </li>
              <li>
                <Link to="/"> {t("qui_somme")} </Link>
              </li>
              <li>
                <Link to="/"> {t("services")}</Link>
              </li>
              <li>
                <Link to="/">{t("temoin")}</Link>
              </li>
            </ul>
            <ul className="box">
              <li className="link_name">Services</li>
              <li>
                <Link to="/">{t("transformation_digi")}</Link>
              </li>
              <li>
                <Link to="/">{t("outil")}</Link>
              </li>
              <li>
                <Link to="/">{t("experience")}</Link>
              </li>
              <li>
                <Link to="/">{t("consultation")}</Link>
              </li>
            </ul>
            <ul className="box">
              <li className="link_name">Contact</li>
              <li>
                <Link to="/">+237 690653506</Link>
              </li>
              <li>{/* <a href="/">tchanchoung@nylstechdigital.com</a> */}</li>
              <li>
                <a href="/"> {t("ouvert")}</a>
              </li>
              <li>
                <a href="/">BP: 24145 {t("cameroun")}</a>
              </li>
            </ul>
            <ul className="box input-box">
              <li className="link_name">Newsletter</li>
              <li>
                <input
                  type="text"
                  className="border-[2px] w-[280px] border-[#49093b] rounded-md p-2 py-2 outline-none text-[12px ] bg-white focus:bg-white"
                  placeholder="Entrer votre email"
                />
              </li>
              <li className="w-full">
                <button className="w-[280px] bg-gradient-to-r from-[#8a2c76] via-purple-700 to-purple-600 p-2 rounded-md text-white outline-none animation duration-300 border-[1px] border-gray-100">
                  Souscrire
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="bottom-details bg-black/30">
          <div className="bottom_text">
            <span className="copyright_text">
              Copyright © 2022 <Link to="/">Nylstech Digital.</Link>Tout Droits
              reservé
            </span>
            <span className="policy_terms">
              <Link to="/">Privacy policy</Link>
              <Link to="/">Termes & conditions</Link>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
