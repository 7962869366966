export const TRANSLATIONS_FR = {
  // header translate

  cameroun: " Douala-Cameroun",

  // navbar translation
  acceuil: "Accueil",
  qui_somme: "Qui sommes-nous ?",
  services: "Savoir faire",
  blog: "Blog",
  contact_us: "Contact",
  temoin: "Témoignage",
  lien_rapide: "Liens Rapides",
  ouvert: "Ouvert de Lundi à Vendredi",

  // big  section

  en_savoir_plus: "EN SAVOIR PLUS",
  contactez_nous: "CONTACTEZ-NOUS",

  // title translate

  title:
    "nous vous offrons des services de qualité disponible à la portée de tous et à un coût abordable",

  // service

  transformation_digi: "Transformation digitale",
  outil: "Outils de production",
  experience: "Experience Client",
  consultation: "Consultation",
  domaine: "Nos domaines d'expertise",

  // partenaires
  partenaire: "Nos partenaires",

  // contact

  contacter:
    "Besoin de nous contactez ? remplissez le formulaire avec votre demande ou trouvez le contact dont vous souhaitez contacter ci-dessous",
  laisse_message: "Laissez-nous un message",
  nom: "Nom",
  email: "Email",
  telephone: "Téléphone",
  objet: "Objet",
  message: "Entrer votre message ici ...",
  envoie: "ENYOYER MAINTENANT",

  // newsletter

  inscription: "Inscription à notre newsletter ",
  inscription_info:
    "Soyez informé des mises à jour et soyez le premier à êtrenotifier de nos offres promotionnelles",

  processus: "Notre Processus de Travail",
};
