import React from "react";
import PetitSection1 from "../components/PetitSection1";
import Title from "../components/Title";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Newsletter from "../Partials/Newsletter/Newsletter";
import Transformation from "../components/Transformation";
import experience from "../assets/experience.png";
import Activitycard from "./Activitycard/Activitycard";
import GoToTop from "../components/GoToTop";
import { useTranslation } from "react-i18next";

function DetailExperience() {
  const { t } = useTranslation();
  return (
    <div>
      <GoToTop />
      <Navbar />
      <PetitSection1 titre="Experience Client" newtitre="détail" />
      <Title header__text="Experience Client" body__text={t("title")} />

      <Transformation
        images={experience}
        header=" Pour vous hisser au-dessus de la concurrence, vous savez que vous ne pouvez plus vous contenter d'offrir une expérience client correcte à celles et ceux qui interagissent avec votre entreprise.

            Votre mission, si vous l'acceptez : marquer les esprits avec une expérience client si fluide et enchanteresse que personne ne pourra vous oublier !
            
            On vous explique tout ce qu'il y a à savoir sur l'expérience client et la (longue) liste des bonnes raisons d'y investir du temps et de l'argent. Et pour vous préparer à réussir découvrez nos meilleurs conseils pour l'améliorer, et quelques exemples d'entreprises qui ont fait leurs preuves en la matière."
      />
      <Activitycard service="experience" />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default DetailExperience;
