import React from "react";
import Footer from "../Partials/Footer/Footer";
import Navbar from "../Partials/Navbar/Navbar";
import Title from "../components/Title";
import Newsletter from "../Partials/Newsletter/Newsletter";
import PetitSection1 from "../components/PetitSection1";
import GoToTop from "../components/GoToTop";
import { useTranslation } from "react-i18next";
import Contact2 from "./Contact/Contact";

export default function Contacter() {
  const { t } = useTranslation();
  return (
    <>
      <GoToTop />
      <Navbar />
      <PetitSection1 titre={t("contact_us")} newtitre="contact" />

      <Title
        header__text="Disponible pour vous servir"
        body__text={t("title")}
      />
      {/* <Contact /> */}
      <Contact2 />
      <Newsletter />
      <Footer />
    </>
  );
}
