import React from "react";
import "./Component.css";
import { slice } from "lodash";
import { BsArrowRight, BsCheck2All } from "react-icons/bs";
import { Link } from "react-router-dom";

function ExpertiseCard(props) {
  return (
    <Link
      to={props.link}
      className="flex flex-row service_card py-6 px-4 group hover:bg-primary bg-white shadow rounded-sm p-2 mb-2 gap-3 cursor-pointer animation duration-300"
    >
      <div className="flex items- justify-center">
        <div className="p-2 w-16 h-16">
          <img src={props.image} width="100%" alt="Expertise pictures" />
        </div>
      </div>

      <div className="h-full flex flex-col justify-between gap-2 group">
        <div>
          <div className="uppercase text-gray-800 text-[26px] group-hover:text-white text font-semibold">
            {props.title}
          </div>
          <span className="text-[13px]">
            Nous vous offrons des services tels que
          </span>
          <div className="group-hover:text-gray-100 grid grid-cols-2 gap-x-4 justify-betwee">
            {slice(props.description, 0, 4).map((item) => {
              return (
                <div className="flex flex-row items-center gap-x-1">
                  <div>
                    <BsCheck2All
                      size={10}
                      className="text-violet-900 group-hover:text-white"
                    />
                  </div>
                  <span className="mb-1 text-[12px] text-cente">
                    {item.label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex flex-row items-center">
          <div
            onClick={() => window.open(props.link, "_self")}
            className="group-hover:bg-transparent text-[12px] p-2 border-2 border-primary group-hover:border-white text-gray-900 px-4 outline-none animation duration-300 flex items-center gap-1"
          >
            <span>En savoir plus</span>
            <span className="animation duratoin-300 ">
              <BsArrowRight size={18} className="group-hover:text-white" />
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ExpertiseCard;
