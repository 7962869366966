import React from "react";
import about from "../../assets/hero.webp";

function About() {
  return (
    <div className="container_custom py-20 bg-[#EAF1FF] ">
      <div className="flex flex-col md:flex-row gap-6">
        <div className="w-full md:w-1/2 flex flex-col">
          <span className=" font-bold text-primary text-4xl mb-2">
            A PROPOS DE NOUS
          </span>

          <div className="flex flex-col gap-6">
            <span className="font-normal leading-7 text-[13px] md:text-xl text-gray-500 md:text-justify">
              Dans un contexte fortement marqué par l’évolution de la
              technologie où les habitudes de consommation des clients se
              transforment, l’accélération de la digitalisation des entreprises
              devient un enjeu majeur pour répondre aux nouveaux défis de
              collaboration, de notoriété et d’expérience client.
            </span>
            <span className="font-normal leading-7 text-[13px] md:text-xl text-gray-500 md:text-justify">
              Il devient donc de plus en plus difficile de générer de la
              croissance et rester compétitif sans une réelle transformation
              intégrant toutes les opportunités qu’offrent le digital. Nos
              experts se tiennent à votre disposition pour vous accompagner.
            </span>
          </div>
        </div>
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <img
            src={about}
            alt="Transform"
            className=" object-contain w-[80%]"
          />
        </div>
      </div>
    </div>
  );
}

export default About;
